<template>
    <div>
        <el-form :inline="true" class="page-search" :model="searchData">
            <div class="left">
                <el-form-item label="">
                    <el-input
                        v-model="searchData.userName"
                        clearable
                        placeholder="请输入用户名"
                    ></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-input
                        v-model="searchData.displayName"
                        clearable
                        placeholder="请输入显示名"
                    ></el-input>
                </el-form-item>
                <el-form-item label="">
                    <!-- <gb-employee
                    clearable
                    v-model="searchData.employeeId"
                    placeholder="请选择员工"
                ></gb-employee> -->
                    <gb-employee
                        ref="employee"
                        v-model="searchData.employeeId"
                        clearable
                        placeholder="请选择员工"
                        api-name="getEmlpoyeesList"
                        :is-cascader="false"
                        :value-filed="'employeeId'"
                        :label-filed="'employeeName'"
                    ></gb-employee>
                </el-form-item>
            </div>
            <div class="right">
                <div class="search-btn">
                    <div class="custom-btn">
                        <el-button
                            type="primary"
                            @click="search"
                        >
                            查询
                        </el-button>
                        <el-button @click="reset">重置</el-button>
                    </div>
                </div>
            </div>
        </el-form>
        <div class="page-content">
            <div class="page-handle-btn">
                <span @click="editItem({ enabled: true, employeeId: '' })">
                    <i
                        class="iconfont icon-benzhouxinzengfenxiaoshang"
                    >添加用户</i>
                </span>
            </div>
            <div class="gb-table">
                <el-table class="gb-table" :data="listData">
                    <el-table-column
                        prop="userId"
                        label="用户ID"
                        width="100"
                    ></el-table-column>
                    <el-table-column
                        prop="userName"
                        label="用户名"
                    ></el-table-column>
                    <el-table-column
                        prop="realName"
                        label="真实姓名"
                    ></el-table-column>
                    <el-table-column
                        prop="displayName"
                        label="显示名"
                    ></el-table-column>
                    <el-table-column
                        prop="employeeId"
                        label="所属员工ID"
                    ></el-table-column>
                    <el-table-column
                        prop="enabled"
                        label="是否有效"
                        :formatter="status"
                    ></el-table-column>
                    <el-table-column
                        prop="updateTime"
                        label="更新时间"
                    ></el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                type="text"
                                @click="editItem(scope.row)"
                            >
                                编辑
                            </el-button>
                            <el-button
                                size="mini"
                                type="text"
                                @click="accountItem(scope.row)"
                            >
                                账号
                            </el-button>
                            <el-button
                                v-permission="'gbei.oa.employee.password'"
                                size="mini"
                                type="text"
                                @click="showPassword(scope.row)"
                            >
                                修改密码
                            </el-button>
                            <el-button
                                size="mini"
                                type="text"
                                @click="removeItem(scope.row)"
                            >
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    :total="dataCount"
                    :current-page="searchData.pageIndex"
                    :page-size="searchData.pageSize"
                    layout="total, prev, pager, next, jumper"
                    @current-change="changePage"
                ></el-pagination>
            </div>
        </div>
        <el-dialog
            :title="editTitle"
            :visible.sync="editVisible"
            width="500px"
            center
            top="20vh"
            :modal="true"
        >
            <el-form
                ref="itemForm"
                :model="currentItem"
                label-position="left"
                status-icon
                label-width="80px"
            >
                <el-form-item
                    label="用户名"
                    prop="userName"
                    :show-message="false"
                    required
                >
                    <el-input
                        v-model.trim="currentItem.userName"
                        clearable
                        maxlength="20"
                        show-word-limit
                        type="text"
                        placeholder="用户名"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="真实姓名"
                    prop="realName"
                    :show-message="false"
                    required
                >
                    <el-input
                        v-model.trim="currentItem.realName"
                        clearable
                        maxlength="20"
                        show-word-limit
                        type="text"
                        placeholder="真实姓名"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="显示名"
                    prop="displayName"
                    :show-message="false"
                    required
                >
                    <el-input
                        v-model.trim="currentItem.displayName"
                        clearable
                        maxlength="20"
                        show-word-limit
                        type="text"
                        placeholder="显示名"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="所属员工"
                    prop="employeeId"
                    :show-message="false"
                >
                    <!-- <gb-employee
                        clearable
                        v-model="currentItem.employeeId"
                        @change="employeeChange"
                    ></gb-employee> -->
                    <gb-employee
                        v-model="currentItem.employeeId"
                        clearable
                        placeholder="请选择员工"
                        api-name="getEmlpoyeesList"
                        :is-cascader="false"
                        :value-filed="'employeeId'"
                        :label-filed="'employeeName'"
                        @change="employeeChange"
                    ></gb-employee>
                </el-form-item>
                <el-row>
                    <el-col :span="12">
                        <el-form-item
                            label="是否有效"
                            prop="enabled"
                            :show-message="false"
                            required
                        >
                            <el-switch
                                v-model="currentItem.enabled"
                                :active-value="true"
                                :inactive-value="false"
                            ></el-switch>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12"></el-col>
                </el-row>
            </el-form>
            <span slot="footer">
                <el-button type="primary" @click="editForm()">确 定</el-button>
                <el-button @click="editVisible = false">取 消</el-button>
            </span>
        </el-dialog>
        <gb-password ref="thePassword"></gb-password>
    </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import gbEmployee from '@/components/Department'
import gbPassword from '@/components/password'

export default {
    name: 'UserMng',
    components: { gbEmployee, gbPassword },
    data () {
        return {
            listData: [],
            dataCount: 0,
            currentItem: {},
            editVisible: false,
            editTitle: '',
            searchData: {
                userName: '',
                displayName: '',
                employeeId: '',
                pageSize: 10,
                pageIndex: 1
            }
        }
    },
    created () {
        let employeeId = parseInt(this.$route.query.employeeId)
        if (employeeId) {
            this.searchData.employeeId = employeeId
        }
        this.fetchData()
    },
    methods: {
        status (row, column, value, index) {
            return value ? '有效' : '无效'
        },
        syncStatus (row, column, value, index) {
            return value ? '是' : '否'
        },
        date (row, column, value, index) {
            return value ? value.substr(0, 10) : ''
        },
        showPassword (item) {
            this.$refs['thePassword'].show(item)
        },
        search () {
            this.changePage(1)
        },
        changePage (value) {
            this.searchData.pageIndex = value
            this.fetchData()
        },
        reset () {
            this.searchData.userName = ''
            this.searchData.displayName = ''
            this.searchData.employeeId = null
            this.$nextTick(() => {
                this.$refs.employee.currentItem = {}
            })
            this.changePage(1)
        },
        fetchData () {
            let that = this
            axios
                .fetch(
                    'userServer',
                    '/permission/queryAdminUsers',
                    this.searchData
                )
                .then(res => {
                    that.listData = res.data
                    that.dataCount = res.total
                })
        },
        editItem (item) {
            this.editVisible = true
            let form = this.$refs['itemForm']
            if (form) {
                form.resetFields()
            }
            this.currentItem = _.clone(item)
            this.editTitle = item.userId ? '编辑用户信息' : '新增用户'
        },
        editForm () {
            let that = this
            this.$refs['itemForm'].validate(valid => {
                if (!valid) {
                    return false
                }
                let item = that.currentItem
                axios
                    .fetch(
                        'userServer',
                        item.userId
                            ? '/permission/updateUser'
                            : '/permission/createUser',
                        item
                    )
                    .then(res => {
                        this.editVisible = false
                        that.$message.success('操作成功')
                        that.fetchData()
                    })
            })
        },
        removeItem (item) {
            let that = this
            this.$confirm(
                '请确认是否删除此用户，一旦删除，相关的用户账号信息也将一并删除，且无法恢复！！！'
            )
                .then(() => {
                    axios
                        .fetch('userServer', '/permission/removeUser', {
                            userId: item.userId
                        })
                        .then(res => {
                            that.$message.success('删除成功')
                            that.fetchData()
                        })
                })
                .catch(() => {})
        },
        accountItem (item) {
            this.$router.push({
                path: 'account',
                query: { userId: item.userId }
            })
        },
        employeeChange (item) {
            if (item) {
                this.currentItem.realName = item.employeeName
                this.currentItem.displayName = item.employeeName
            }
        }
    }
}
</script>
