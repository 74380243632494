<template>
    <div class="content">
        <div class="page-content">
            <div class="gb-table">
                <div class="page-handle-btn">
                    <span
                        @click="
                            editItem({
                                userType: '2',
                                userId: '',
                                enabled: true
                            })
                        "
                    >
                        <i
                            class="iconfont iconfont icon-benzhouxinzengfenxiaoshang"
                        >添加关系</i>
                    </span>
                </div>
                <el-table class="ub-table" :data="listData" height="500">
                    <el-table-column
                        type="index"
                        label="序号"
                    ></el-table-column>
                    <el-table-column
                        prop="userType"
                        label="关系类型"
                        :formatter="userType"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="userId"
                        label="所属ID"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="remark"
                        label="备注"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="title"
                        label="所属角色"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="enabled"
                        label="是否有效"
                        :formatter="status"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="createTime"
                        label="创建时间"
                        align="center"
                    ></el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                type="text"
                                @click="grantItem(scope.row)"
                            >
                                授权
                            </el-button>
                            <el-button
                                type="text"
                                size="mini"
                                @click="removeItem(scope.row)"
                            >
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <el-dialog
            :title="editTitle"
            :visible.sync="editVisible"
            width="500px"
            center
            top="20vh"
            :modal="true"
        >
            <el-form
                ref="itemForm"
                :model="currentItem"
                label-position="left"
                status-icon
                label-width="80px"
            >
                <el-form-item
                    label="关系类型"
                    prop="userType"
                    :show-message="false"
                    required
                >
                    <el-select
                        v-model="currentItem.userType"
                        @change="userTypeChange"
                    >
                        <el-option
                            v-for="(value, key) in userTypes"
                            :key="key"
                            :value="key"
                            :label="value"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    v-if="currentItem.userType == 0"
                    label="用户"
                    prop="userId"
                    :show-message="false"
                    required
                >
                    <el-select
                        v-model="currentItem.userId"
                        filterable
                        default-first-option
                        @change="userChange"
                    >
                        <el-option
                            v-for="item in users"
                            :key="item.userId"
                            :value="item.userId"
                            :label="item.displayName"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    v-if="currentItem.userType == 1"
                    label="员工"
                    prop="userId"
                    :show-message="false"
                    required
                >
                    <gb-employee
                        v-model="currentItem.userId"
                        clearable
                        api-name="getEmlpoyeesList"
                        :is-cascader="false"
                        :value-filed="'employeeId'"
                        :label-filed="'employeeName'"
                        @change="employeeChange"
                    ></gb-employee>
                </el-form-item>
                <el-form-item
                    v-if="currentItem.userType == 2"
                    label="岗位"
                    prop="userId"
                    :show-message="false"
                    required
                >
                    <el-select
                        v-model="currentItem.userId"
                        filterable
                        default-first-option
                        @change="jobChange"
                    >
                        <el-option
                            v-for="item in jobs"
                            :key="item.id"
                            :value="item.id"
                            :label="item.jobName"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    v-if="currentItem.userType == 3"
                    label="组织"
                    prop="userId"
                    :show-message="false"
                    required
                >
                    <el-select
                        v-model="currentItem.userId"
                        filterable
                        default-first-option
                        @change="deptChange"
                    >
                        <el-option
                            v-for="item in depts"
                            :key="item.id"
                            :value="item.id"
                            :label="item.groupName"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    label="所属角色"
                    prop="roleId"
                    :show-message="false"
                    required
                >
                    <el-select
                        v-model="currentItem.roleId"
                        filterable
                        default-first-option
                    >
                        <el-option
                            v-for="item in roles"
                            :key="item.roleId"
                            :value="item.roleId"
                            :label="item.title"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="备注" prop="remark" :show-message="false">
                    <el-input
                        v-model.trim="currentItem.remark"
                        readonly
                        clearable
                        maxlength="255"
                        show-word-limit
                        type="text"
                        placeholder="描述"
                    ></el-input>
                </el-form-item>
                <el-row>
                    <el-col :span="12">
                        <el-form-item
                            label="是否有效"
                            prop="enabled"
                            :show-message="false"
                            required
                        >
                            <el-switch
                                v-model="currentItem.enabled"
                                :active-value="true"
                                :inactive-value="false"
                            ></el-switch>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12"></el-col>
                </el-row>
            </el-form>
            <span slot="footer">
                <el-button type="primary" @click="editForm()">确 定</el-button>
                <el-button @click="editVisible = false">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import gbEmployee from '@/components/Department'
import oaApi from '@/services/oa'
import userApi from '@/services/user'

const userTypes = {
    0: '用户',
    1: '员工',
    2: '岗位',
    3: '组织'
}

export default {
    name: 'UserRole',
    components: { gbEmployee },
    data () {
        return {
            roles: [],
            jobs: [],
            depts: [],
            users: [],
            listData: [],
            currentItem: {},
            editVisible: false,
            editTitle: '新增角色关系',
            userTypes: userTypes
        }
    },
    async created () {
        let res = await Promise.all([
            userApi.getRoles(),
            userApi.getAdminUsers(),
            oaApi.getAllDepts(),
            oaApi.getJobList()
        ])
        ;[this.roles, this.users, this.depts, this.jobs] = res.map(
            item => item.data
        )
        // axios.fetch('userServer', '/permission/getRoles', {}).then(res => {
        //     this.roles = res.data
        // })
        // axios.fetch('oaServer', '/oa/oa-job-list', {}).then(res => {
        //     this.jobs = res.data
        // })
        // axios.fetch('oaServer', '/oa/oa-group-list', {}).then(res => {
        //     this.depts = res.data
        // })
        // axios.fetch('userServer', '/permission/getAdminUsers', {}).then(res => {
        //     this.users = res.data
        // })
        this.fetchData()
    },
    methods: {
        status (row, column, value, index) {
            return value ? '有效' : '无效'
        },
        syncStatus (row, column, value, index) {
            return value ? '是' : '否'
        },
        date (row, column, value, index) {
            return value ? value.substr(0, 10) : ''
        },
        fetchData () {
            let that = this
            axios
                .fetch('userServer', '/permission/getUserRoles', {})
                .then(res => {
                    that.listData = res.data
                })
        },
        editItem (item) {
            this.editVisible = true
            let form = this.$refs['itemForm']
            if (form) {
                form.resetFields()
            }
            this.currentItem = item
        },
        editForm () {
            let that = this
            this.$refs['itemForm'].validate(valid => {
                if (!valid) {
                    return false
                }
                let item = that.currentItem
                let sameItem = that.listData.find(it => {
                    if (
                        it.userType == item.userType &&
                        it.userId == item.userId &&
                        it.roleId == item.roleId
                    ) {
                        return true
                    }
                    return false
                })
                if (sameItem) {
                    that.$message.error('已存在相同的关系')
                    return
                }
                axios
                    .fetch('userServer', '/permission/createUserRole', item)
                    .then(res => {
                        this.editVisible = false
                        that.$message.success('操作成功')
                        that.fetchData()
                    })
            })
        },
        removeItem (item) {
            let that = this
            this.$confirm('请确认是否删除？')
                .then(() => {
                    axios
                        .fetch('userServer', '/permission/removeUserRole', {
                            userType: item.userType,
                            userId: item.userId,
                            roleId: item.roleId
                        })
                        .then(res => {
                            that.$message.success('删除成功')
                            that.fetchData()
                        })
                })
                .catch(() => {})
        },
        grantItem (item) {
            this.$router.push({
                path: 'relation',
                query: { roleId: item.roleId }
            })
        },
        userType (row, column, value, index) {
            return userTypes[value]
        },
        userTypeChange (value) {
            this.currentItem.userId = undefined
            this.currentItem.remark = undefined
        },
        jobChange (value) {
            let job = this.jobs.find(item => item.id == value)
            if (job) {
                this.currentItem.remark = job.jobName
            }
        },
        employeeChange (item) {
            if (item) {
                this.currentItem.remark = item.employeeName
            }
        },
        deptChange (value) {
            let dept = this.depts.find(item => item.id == value)
            if (dept) {
                this.currentItem.remark = dept.groupName
            }
        },
        userChange (value) {
            let user = this.users.find(item => item.userId == value)
            if (user) {
                this.currentItem.remark = user.displayName
            }
        }
    }
}
</script>

<style lang="less" scoped>
/deep/.el-select {
    display: inherit;
}
</style>
